import styles from './Meme.module.css';
import { useState } from 'react';
import { useData } from '../hooks/useData';

const url = "https://api.imgflip.com/get_memes";

const Meme = () => {
    const { data } = useData(url);
    const [meme, setMeme] = useState({top: "", bottom:"", url:"/images/memeimg.png", description:"first meme"});

    const handleChange = (e) =>{
        const {name, value} = e.target;
        setMeme(prevState => ({...prevState, [name]:value}));
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
    }

    const getNewImage = () => {
        const memeArray = data.data.memes;
        const random = Math.floor(Math.random() * memeArray.length);
        const imgURL = memeArray[random].url;
        const description = memeArray[random].name;
        setMeme( (prevState) => ({...prevState, url:imgURL, description:description}))
    }

    return (
        <main className={styles.container}>
            <form onSubmit={handleSubmit} className={styles.meme_form}>
                <div className={styles.meme_input}>
                    <input
                        type="text"
                        placeholder="Shut up"
                        value={meme.top}
                        name="top"
                        onChange={handleChange}/>
                    <input
                        type="text"
                        placeholder="and take my money"
                        value={meme.bottom}
                        name="bottom"
                        onChange={handleChange}/>  
                </div>
                <button onClick={getNewImage}>Get a new meme image 🖼️</button>
            </form>
            <div className={styles.container_img}>
                <img className={styles.meme_img} src={meme.url} alt={meme.description} height={meme.height} width={meme.width}/>
                <p className={styles.top}>{meme.top}</p>
                <p className={styles.bottom}>{meme.bottom}</p>
            </div>
        </main>
    )
}

export default Meme;