import styles from './Header.module.css';

const Header = () => {
  return (
    <div className={styles.container}>
        <img src="/images/troll-face.png" alt="" />
        <span>Meme Generator</span>
        <p>Project 3 - By Breno</p>
    </div>
  )
}

export default Header;